import React from "react";
import { graphql, useStaticQuery } from "gatsby"
import ReactMarkdown from "react-markdown";

const TermsAndConditions = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiTermsAndConditions{
        nodes {
          description
          title
        }
      }
    }`)
  const { title, description } = data.allStrapiTermsAndConditions.nodes[0]
  return (
    <div className="privacyPolicyContainer">
      <div style={{ width: "100%" }} className="content m-3">
        <div className="textContent">
          <h1>{title}</h1>
          <ReactMarkdown>
            {description}
          </ReactMarkdown>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;