import React from 'react'
import Layout from "../components/layout";
import TermsAndCondition from '../components/termsAndConditions'

const TermsAndConditions = () => {
  return (
    <Layout title="terms-and-conditions">
      <TermsAndCondition />
    </Layout>
  );
}

export default TermsAndConditions;